import "./Main.css";

const Main = (props) => {
  return (
    <>
      <div
        className="App"
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <div className="Titel">
          <div className="NestedTitle">
            <div className="Ueberschrift">
              B U N T E &nbsp;&nbsp; S C H E R B E N
            </div>

            <div className="Description-01">
              <div>
                <b>Wir spielen wieder!</b>
              </div>
              <div className="Konzertdaten">
                <div></div>
                <div className="main-title-detail">
                  Mit das schönste am Musikmachen ist es, euch zu treffen und
                  für euch live zu spielen. Das machen wir in diesem Jahr wieder
                  gerne! Schaut im in unseren Terminen, wann wir uns wieder
                  sehen können.
                  <div>
                    <a
                      href="https://www.bunte-scherben.de/jpg/Flyer_FeuerwacheHoenow.jpg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Flyer)
                    </a>
                  </div>
                  Wer die Aufnahmen hören will, kann:
                  <ol className="moeglichkeiten">
                    <li>
                      jemandem aus der Band eine Spende von 10€ geben und die CD
                      direkt in den Händen halten
                    </li>
                    <li>
                      auf dieser Webseite zu{" "}
                      <span
                        className="Flyerlink"
                        onClick={() => props.clickHandler(3)}
                      >
                        Audio
                      </span>{" "}
                      gehen und sich die Aufnahmen anhören
                    </li>
                    <li>
                      uns bei{" "}
                      <a
                        href="https://open.spotify.com/intl-de/album/6gTFENi3pJrHeqmQfqpcGW?si=WYFHAc0ESKOMIb-n9eAptw"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Spotify
                      </a>{" "}
                      in seine Lieblingsplaylist hinzufügen und hören
                    </li>
                    <li>
                      auf{" "}
                      <a
                        href="https://youtu.be/PkI_PjMF2U0?feature=shared"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        youtube
                      </a>{" "}
                      unsere Songs genießen
                    </li>
                    <li>
                      auf allen anderen gängigen Plattformen nach "BunteScherben
                      - nur leben" suchen und die Songs genießen
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ImageContainer">
          <img
            alt="eineKollarsche"
            data-src="https://www.bunte-scherben.de/ressources/images/gesamt_f.png"
            src="https://www.bunte-scherben.de/ressources/images/gesamt_f.png"
            className="dasBild"
          ></img>
        </div>
      </div>
    </>
  );
};

export default Main;
