import { useRef, useState } from "react";
import "./App.css";

import BandDetails from "./Pages/BandDetails/BandDetailsContainer";
import Main from "./Pages/Main";

import AudioPlayerContainer from "./Pages/AudioPlayer/AudioPlayerContainer";
import NewsletterContainer from "./Pages/Newsletter/NewsletterContainer";
import useImagePreloader from "./Shared/Hooks/useImagePreloader";
import { dieBilderListe } from "./Shared/ImageList";
import BildergalerieContainer from "./Pages/Bildergalerie/BildergalerieContainer";
import { useEffect } from "react";

import VideosContainer from "./Pages/Videos/VideosContainer";
import TopMenu from "./Pages/Menu/TopMenu";
import Termine from "./Pages/Termine/Termine";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function App() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [selectedMenuItem, setSelectedMenuItem] = useState(1);
  const [BandDetalsVisible, setBandDetailsVisible] = useState(false);
  const myRef = useRef(null);
  const myRef_02 = useRef(null);
  const myRef_03 = useRef(null);
  const myRef_04 = useRef(null);
  const myRef_05 = useRef(null);
  const myRef_06 = useRef(null);
  const scrollRef = useRef(null);

  const { imagesPreloaded } = useImagePreloader(dieBilderListe);

  useEffect(() => {
    const vh = windowSize.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    fetch("https://bunte-scherben.de/_PHPs/saveScreenDimensions.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        breite: windowSize.innerWidth,
        hoehe: windowSize.innerHeight,
      }),
    });
  }, [windowSize.innerHeight, windowSize.innerWidth]);

  const elementScrollData = (value) => {
    if (value.currentTarget.scrollTop >= scrollRef.current.offsetTop)
      setSelectedMenuItem(1);
    if (value.currentTarget.scrollTop >= myRef.current.offsetTop)
      setSelectedMenuItem(2);
    if (value.currentTarget.scrollTop >= myRef_02.current.offsetTop)
      setSelectedMenuItem(3);
    if (value.currentTarget.scrollTop >= myRef_03.current.offsetTop) {
      setSelectedMenuItem(4);
    }
    if (value.currentTarget.scrollTop >= myRef_04.current.offsetTop)
      setSelectedMenuItem(5);
    if (value.currentTarget.scrollTop >= myRef_05.current.offsetTop)
      setSelectedMenuItem(6);
    if (value.currentTarget.scrollTop >= myRef_06.current.offsetTop)
      setSelectedMenuItem(7);
  };

  const scroll2Ref = (wohin) => {
    if (wohin === 0) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (wohin === 1) {
      myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (wohin === 2) {
      myRef_02.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (wohin === 3) {
      myRef_03.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (wohin === 4) {
      myRef_04.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (wohin === 5) {
      myRef_05.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (wohin === 6) {
      myRef_06.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const setDetailsVisible = (DetaislVisible) => {
    setBandDetailsVisible(DetaislVisible);
  };

  return (
    <>
      <div className="container" onScroll={elementScrollData}>
        {!BandDetalsVisible && (
          <TopMenu
            selectedMenuItem={selectedMenuItem}
            clickHandler={scroll2Ref}
          />
        )}
        <section className="one" ref={scrollRef}>
          <Main
            scroll2Ref={scroll2Ref}
            selectedMenuItem={selectedMenuItem}
            clickHandler={scroll2Ref}
          ></Main>
        </section>
        <section className="two" ref={myRef}>
          <Termine
            scroll2Ref={scroll2Ref}
            BandDetailsVisible={setDetailsVisible}
          ></Termine>
        </section>
        <section className="three" ref={myRef_02}>
          <BandDetails
            scroll2Ref={scroll2Ref}
            BandDetailsVisible={setDetailsVisible}
          ></BandDetails>
        </section>
        <section className="four" ref={myRef_03}>
          <AudioPlayerContainer scroll2Ref={scroll2Ref} />
        </section>
        <section className="five" ref={myRef_04}>
          <VideosContainer />
        </section>
        <section className="six" ref={myRef_05}>
          <BildergalerieContainer
            scroll2Ref={scroll2Ref}
            windowSize={windowSize}
          />
        </section>
        <section className="seven" ref={myRef_06}>
          <NewsletterContainer />
        </section>
      </div>
    </>
  );
}

export default App;
