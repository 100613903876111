import { Parallax, ParallaxLayer } from "@react-spring/parallax";

import Olli from "./Olli";
import Pedder from "./Pedder";
import Bastel from "./Bastel";
import Enn from "./Enn";
import Daniel from "./Daniel";
import backgroundImage from "../../ressources/background_concert_03.png";
import { useRef } from "react";

const BandDetailsParallax = (props) => {
  const ref = useRef();
  const scrollTo = (index) => {
    ref.current.scrollTo(index);
  };

  return (
    <>
      <Parallax
        horizontal
        pages={5}
        style={{ top: "0", left: "0", overflow: "hidden" }}
        ref={ref}
      >
        <ParallaxLayer
          offset={0}
          factor={5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ff6d6d",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: " cover",
            backgroundPosition: "center",
          }}
        ></ParallaxLayer>

        <ParallaxLayer
          onClick={() => scrollTo(1)}
          offset={0}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <Olli setParallaxInvisible={props.setParallaxInvisible} />
        </ParallaxLayer>

        <ParallaxLayer
          onClick={() => scrollTo(2)}
          speed={0.5}
          offset={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <Pedder setParallaxInvisible={props.setParallaxInvisible} />
        </ParallaxLayer>

        <ParallaxLayer
          onClick={() => scrollTo(3)}
          speed={0.8}
          offset={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <Bastel setParallaxInvisible={props.setParallaxInvisible} />
        </ParallaxLayer>

        <ParallaxLayer
          onClick={() => scrollTo(4)}
          speed={0.8}
          offset={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <Daniel setParallaxInvisible={props.setParallaxInvisible} />
        </ParallaxLayer>

        <ParallaxLayer
          onClick={() => scrollTo(0)}
          speed={0.8}
          offset={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <Enn setParallaxInvisible={props.setParallaxInvisible} />
        </ParallaxLayer>
      </Parallax>
    </>
  );
};

export default BandDetailsParallax;
