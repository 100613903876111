import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ImpressumDialog = (props) => {
  return (
    <div>
      <DialogTitle>Impressum</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "5px" }}>
          <b>Dies ist der Webauftritt der Band 'Bunte Scherben'</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "3px" }}>
          Angaben gemäß § 5 TMG
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Enrico Frank
        </DialogContentText>
        <DialogContentText>Hauptstr. 51c</DialogContentText>
        <DialogContentText>10317 Berlin</DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Telefon: 0176-248 70 434
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          E-Mail: wir@bunte-scherben.de
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Webseite: https://www.bunte-scherben.de
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeHandler}>Bitte schließe mich</Button>
      </DialogActions>
    </div>
  );
};
export default ImpressumDialog;
