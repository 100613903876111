import { Grid } from "@mui/material";
import Menueleiste from "./Menueleiste";
import TextScherben from "./TextScherben";

const Enn = (props) => {
  return (
    <>
      <div style={{ margin: "5%" }}>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={2}
          style={{
            height: "100%",
            maxWidth: "900px",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <img
              src="https://www.bunte-scherben.de/ressources/images/enn.jpg"
              alt="Enn"
              loading="lazy"
              width="100%"
              style={{ borderRadius: "10%" }}
            />
          </Grid>
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <div style={{ backgroundColor: "rgba(0,0,0,0.6)", padding: "5px" }}>
              <b>Enn</b>, jemand, der die Band im innersten zusammen hält. Er
              zupft an den dicken Saiten im Hintergrund und bildet doch die
              Brücke zwischen donnerndem Rhythmus und bezaubernden Melodien. Ein
              wichtiges Element der <TextScherben />.
            </div>
          </Grid>
        </Grid>
        <Menueleiste
          setParallaxInvisible={props.setParallaxInvisible}
          letzter={true}
        />
      </div>
    </>
  );
};
export default Enn;
