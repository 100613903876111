import { Grid } from "@mui/material";
import Menueleiste from "./Menueleiste";
import TextScherben from "./TextScherben";

const Daniel = (props) => {
  return (
    <>
      <div style={{ margin: "5%" }}>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={2}
          style={{
            height: "100%",
            maxWidth: "900px",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <img
              src="https://www.bunte-scherben.de/ressources/images/Daniel.jpg"
              alt="Daniel"
              loading="lazy"
              width="100%"
              style={{ borderRadius: "10%" }}
            />
          </Grid>
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <div style={{ backgroundColor: "rgba(0,0,0,0.6)", padding: "5px" }}>
              <b>Daniel</b>, der Mann, der die Band im innersten zusammen hält.
              Er spielt virtuos auf den dünnen Saiten, textet mit Herzblut und
              Gefühl und erfindet den ihm eigenen Dampfer-Sound. Er kann gut
              laut und immer besser leise. Ohne ihn wären die <TextScherben />{" "}
              nicht was sie sind, ein Wunder.
            </div>
          </Grid>
        </Grid>
        <Menueleiste setParallaxInvisible={props.setParallaxInvisible} />
      </div>
    </>
  );
};
export default Daniel;
