import { Box, Button, Dialog } from "@mui/material";
import { useState } from "react";
import DatenschutzDialog from "./DatenschutzDialog";
import FanDialog from "./FanDialog";
import ImpressumDialog from "./ImpressumDialog";

import "./NewsletterContainer.css";

const NewsletterContainer = (props) => {
  const [open, setOpen] = useState(false);
  const [ImpressumOpen, setImpressumOpen] = useState(false);
  const [DatenschutzOpen, setDatenschutzOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleImpressumClose = () => {
    setImpressumOpen(false);
  };

  const handleDatenschutzClose = () => {
    setDatenschutzOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div
      className="Fan-App"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div className="Impressum">
        <Button
          variant="text"
          sx={{ color: "#f7b264" }}
          onClick={() => setImpressumOpen(true)}
        >
          Impressum
        </Button>{" "}
        |{" "}
        <Button
          variant="text"
          sx={{ color: "#f7b264" }}
          onClick={() => setDatenschutzOpen(true)}
        >
          Datenschutzerklärung
        </Button>
      </div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={handleToggle}
          sx={{
            maxWidth: "80%",
            fontSize: "20px",
            borderWidth: "1px",
            borderRadius: "20px",
            backgroundColor: "#d8e6d621",
            color: "#fff",
          }}
        >
          Klicke hier, wenn du Fan dieser Band werden und Updates erhalten
          möchtest
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <FanDialog closeHandler={handleClose} />
      </Dialog>

      <Dialog open={ImpressumOpen} onClose={handleImpressumClose}>
        <ImpressumDialog closeHandler={handleImpressumClose} />
      </Dialog>

      <Dialog open={DatenschutzOpen} onClose={handleDatenschutzClose}>
        <DatenschutzDialog closeHandler={handleDatenschutzClose} />
      </Dialog>
    </div>
  );
};

export default NewsletterContainer;
