import React from "react";
import { useState } from "react";
import Lightbox from "react-spring-lightbox";
import NavigationHeader from "./NavigationHeader";
import NavigationButton from "./NavigationButton";
import { useEffect } from "react";

const LightboxContainer = ({
  lightboxOpen,
  closeHandler,
  selectedImage,
  imageList,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedImage);
  const [backButtonDisabled, setBackButtonDisabled] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  useEffect(() => {
    console.log("Welches Bild: ", selectedIndex, " ", selectedImage);
    if (selectedIndex === 0) {
      setBackButtonDisabled(true);
    } else {
      setBackButtonDisabled(false);
    }
    if (selectedIndex === imageList.length - 1) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
  }, [selectedIndex, imageList.length, selectedImage]);

  const gotoPrevious = () => {
    setSelectedIndex(selectedIndex - 1);
  };

  const gotoNext = () => {
    setSelectedIndex(selectedIndex + 1);
  };

  return (
    <div style={{ position: "relative" }}>
      <Lightbox
        isOpen={lightboxOpen}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={imageList}
        currentIndex={selectedIndex}
        /* Add your own UI */
        renderHeader={() => (
          <NavigationHeader
            onClick={closeHandler}
            selectedItem={selectedIndex + 1}
            maxItems={imageList.length}
          />
        )}
        // renderFooter={() => (<CustomFooter />)}

        renderNextButton={() => (
          <NavigationButton
            position={"right"}
            onClick={gotoNext}
            disabled={nextButtonDisabled}
          />
        )}
        renderPrevButton={() => (
          <NavigationButton
            position={"left"}
            onClick={gotoPrevious}
            disabled={backButtonDisabled}
          />
        )}
        // renderImageOverlay={() => (<ImageOverlayComponent >)}

        /* Add styling */
        className="cool-class"
        style={{ background: "black" }}
        /* Handle closing */
        onClose={closeHandler}
        /* Use single or double click to zoom */
        singleClickToZoom
        /* react-spring config for open/close animation */
        pageTransitionConfig={{
          from: { transform: "scale(0.75)", opacity: 0 },
          enter: { transform: "scale(1)", opacity: 1 },
          leave: { transform: "scale(0.75)", opacity: 0 },
          config: { mass: 1, tension: 320, friction: 32 },
        }}
      />
    </div>
  );
};

export default LightboxContainer;
