import { Grid } from "@mui/material";
import Menueleiste from "./Menueleiste";
import TextScherben from "./TextScherben";

const Pedder = (props) => {
  return (
    <>
      <div style={{ margin: "5%" }}>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={2}
          style={{
            height: "100%",
            maxWidth: "900px",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <img
              src="https://www.bunte-scherben.de/ressources/images/pedder.jpg"
              alt="Pedder"
              loading="lazy"
              width="100%"
              style={{ borderRadius: "10%" }}
            />
          </Grid>
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <div style={{ backgroundColor: "rgba(0,0,0,0.6)", padding: "5px" }}>
              <b>Pedder</b>, der Mensch, der die Band im innersten zusammen
              hält. Ein Quell unerschöpflicher Ideen. Er zupft die dünnen Saiten
              mit ihm eigener Melodie und Rhythmus. Der Meister unserer Effekte
              und Kreativität. Kein Barré ist ihm fremd. Die <TextScherben />{" "}
              wären ohne ihn undenkbar
            </div>
          </Grid>
        </Grid>
        <Menueleiste setParallaxInvisible={props.setParallaxInvisible} />
      </div>
    </>
  );
};
export default Pedder;
