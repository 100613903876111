import { useEffect } from "react";
import { useState } from "react";
import { useTransition, animated } from "react-spring";

import "./Artwork.css";

const slide = [
  "Artwork_01.png",
  "Artwork_02.png",
  "Artwork_03.png",
  "Artwork_04.png",
  "Artwork_05.png",
  "Artwork_06.png",
  "Artwork_07.png",
  "Artwork_08.png",
  "Artwork_09.png",
  "Artwork_10.png",
  "Artwork_11.png",
  "Artwork_12.png",
  "Artwork_13.png",
  "Artwork_14.png",
  "Artwork_15.png",
  "Artwork_16.png",
  "Artwork_17.png",
  "Artwork_18.png",
  "Artwork_19.png",
  "Artwork_20.png",
  "Artwork_21.png",
  "Artwork_22.png",
  "Artwork_23.png",
  "Artwork_24.png",
  "Artwork_25.png",
  "Artwork_26.png",
  "Artwork_27.png",
  "Artwork_28.png",
  "Artwork_29.png",
  "Artwork_30.png",
  "Artwork_31.png",
];

//backgroundImage: `url(${backgroundImage})`,

const Artwork = ({ isPlaying }) => {
  const [index, set] = useState(0);
  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 3000 },
  });
  useEffect(() => {
    const t = setInterval(
      () => set((state) => (state + 1) % slide.length),
      10000
    );
    return () => clearTimeout(t);
  }, []);

  return (
    <>
      {isPlaying ? (
        <div className="artwork">
          {transitions((style, i) => (
            <animated.div
              className="bg"
              style={{
                ...style,
                backgroundImage: `url(https://bunte-scherben.de/ressources/images/Audioplayer/${slide[i]})`,
              }}
            />
          ))}
        </div>
      ) : (
        <div className="artwork">
          <div
            className="bg"
            style={{
              backgroundImage: `url(https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_01.png)`,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Artwork;
