import { Grid } from "@mui/material";
import Menueleiste from "./Menueleiste";
import TextScherben from "./TextScherben";

const Olli = (props) => {
  return (
    <>
      <div style={{ margin: "5%" }}>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={2}
          style={{
            height: "100%",
            maxWidth: "900px",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <img
              src="https://www.bunte-scherben.de/ressources/images/Olli.jpg"
              alt="Olli"
              loading="lazy"
              width="100%"
              style={{ borderRadius: "10%" }}
            />
          </Grid>
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <div style={{ backgroundColor: "rgba(0,0,0,0.6)", padding: "5px" }}>
              <b>Olli</b>, der Sänger, der die Band im innersten zusammen hält.
              Mal sanft, mal Rock. Trifft immer den richtigen Ton, beim Singen
              und Schreiben der Texte. Präsent und empathisch am Gesang,
              konzentriert und diszipliniert an der Gitarre. Im besten Sinne
              unsere Rampensau, die die <TextScherben /> ausmacht.
            </div>
          </Grid>
        </Grid>
        <Menueleiste setParallaxInvisible={props.setParallaxInvisible} />
      </div>
    </>
  );
};
export default Olli;
