import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import BandDetailsParallax from "./BandDetailsParallax";

const BandDetails = (props) => {
  const [DetailsVisible, setDetailsVisible] = useState(false);

  useEffect(() => {
    props.BandDetailsVisible(DetailsVisible);
  }, [DetailsVisible, props]);

  const setParallaxInvisible = () => {
    setDetailsVisible(false);
  };

  return (
    <>
      <div
        className="BandDetails-App"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        {DetailsVisible ? (
          <BandDetailsParallax setParallaxInvisible={setParallaxInvisible} />
        ) : (
          <div style={{ height: "100%", position: "relative" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "90%", flexDirection: "column" }}
            >
              <div>
                <Button
                  style={{
                    fontSize: "20px",
                    color: "whitesmoke",
                    borderWidth: "1px",
                    borderRadius: "20px",
                    backgroundColor: "#d8e6d621",
                  }}
                  onClick={() => setDetailsVisible(true)}
                >
                  Klicke hier, um mehr <br /> über uns zu erfahren
                </Button>
              </div>
            </Box>
          </div>
        )}
      </div>
    </>
  );
};
export default BandDetails;
