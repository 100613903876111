import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const DatenschutzDialog = (props) => {
  return (
    <div>
      <DialogTitle>Datenschutzerklärung</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: "5px" }}>
          <b>Allgemeiner Hinweis und Pflichtinformationen</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>Benennung der verantwortlichen Stelle</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Ansprechpartner: Enrico Frank
          <br /> Hauptstr. 51c<br></br> 10317 Berlin
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
          Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
          Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
          formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
          Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
          Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen
          ist der Landesdatenschutzbeauftragte des Bundeslandes Berlin. Der
          folgende Link stellt eine Liste der Datenschutzbeauftragten sowie
          deren Kontaktdaten bereit:<br></br>
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Link - BfDI
          </a>
          .
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>Recht auf Datenübertragbarkeit</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
          Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
          Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie
          die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </DialogContentText>

        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
          Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an
          uns wenden.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>SSL- bzw. TLS-Verschlüsselung</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
          Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
          Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
          über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
          erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
          Ihres Browsers und am Schloss-Symbol in der Browserzeile.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>Server-Log-Dateien</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          Besuchte Seite auf unserer Domain <br></br>Datum und Uhrzeit der
          Serveranfrage<br></br> Browsertyp und Browserversion<br></br>{" "}
          Verwendetes Betriebssystem<br></br>
          Referrer URL<br></br> Hostname des zugreifenden Rechners<br></br>{" "}
          IP-Adresse<br></br>verwendete Bildschirmauflösung<br></br> Es findet
          keine Zusammenführung dieser Daten mit anderen Datenquellen statt.
          Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der
          die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>Registrierung auf dieser Website</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <p>
            Zur Nutzung bestimmter Funktionen können Sie sich auf unserer
            Website registrieren. Die übermittelten Daten dienen ausschließlich
            zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes. Bei
            der Registrierung abgefragte Pflichtangaben sind vollständig
            anzugeben. Andernfalls werden wir die Registrierung ablehnen.
          </p>
          <br></br>
          <p>
            Im Falle wichtiger Änderungen, etwa aus technischen Gründen,
            informieren wir Sie per E-Mail. Die E-Mail wird an die Adresse
            versendet, die bei der Registrierung angegeben wurde.
          </p>
          <br></br>
          <p>
            Die Verarbeitung der bei der Registrierung eingegebenen Daten
            erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist
            jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung
            per E-Mail. Die Rechtmäßigkeit der bereits erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          <br></br>
          Wir speichern die bei der Registrierung erfassten Daten während des
          Zeitraums, den Sie auf unserer Website registriert sind. Ihren Daten
          werden gelöscht, sollten Sie Ihre Registrierung aufheben. Gesetzliche
          Aufbewahrungsfristen bleiben unberührt.
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>Kontaktformular</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <p>
            Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
            Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder
            um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten
            findet ohne Ihre Einwilligung nicht statt.
          </p>
          <br></br>
          <p>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs.
            1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung
            ist jederzeit möglich. Für den Widerruf genügt eine formlose
            Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <br></br>
          <p>
            Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis
            Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder keine Notwendigkeit der Datenspeicherung mehr
            besteht. Zwingende gesetzliche Bestimmungen - insbesondere
            Aufbewahrungsfristen - bleiben unberührt.
          </p>
          <br></br>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <b>Newsletter-Daten</b>
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "5px" }}>
          <p>
            Zum Versenden unseres Newsletters benötigen wir von Ihnen eine
            E-Mail-Adresse. Eine Verifizierung der angegebenen E-Mail-Adresse
            ist notwendig und der Empfang des Newsletters ist einzuwilligen.
            Ergänzende Daten werden nicht erhoben oder sind freiwillig. Die
            Verwendung der Daten erfolgt ausschließlich für den Versand des
            Newsletters.
          </p>
          <br></br>
          <p>
            Die bei der Newsletteranmeldung gemachten Daten werden
            ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit.
            a DSGVO) verarbeitet. Ein Widerruf Ihrer bereits erteilten
            Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
            formlose Mitteilung per E-Mail oder Sie melden sich über den
            "Austragen"-Link im Newsletter ab. Die Rechtmäßigkeit der bereits
            erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <br></br>
          <p>
            Zur Einrichtung des Abonnements eingegebene Daten werden im Falle
            der Abmeldung gelöscht. Sollten diese Daten für andere Zwecke und an
            anderer Stelle an uns übermittelt worden sein, verbleiben diese
            weiterhin bei uns.
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeHandler}>Bitte schließe mich</Button>
      </DialogActions>
    </div>
  );
};
export default DatenschutzDialog;
