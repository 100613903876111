import React from "react";
import { ReactComponent as Play } from "../../ressources/AudioPlayer/play.svg";
import { ReactComponent as Pause } from "../../ressources/AudioPlayer/pause.svg";
import { ReactComponent as Next } from "../../ressources/AudioPlayer/next.svg";
import { ReactComponent as Prev } from "../../ressources/AudioPlayer/prev.svg";
import { Grid } from "@mui/material";

const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
  prevVisible,
  nextVisible,
}) => {
  return (
    <div className="audio-controls">
      <Grid container spacing={1} align="center" justify="center">
        <Grid item xs={4}>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              {prevVisible ? (
                <button
                  type="button"
                  className="prev"
                  aria-label="Previous"
                  onClick={onPrevClick}
                >
                  <Prev />
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              {isPlaying ? (
                <button
                  type="button"
                  className="pause"
                  onClick={() => onPlayPauseClick(false)}
                  aria-label="Pause"
                >
                  <Pause />
                </button>
              ) : (
                <button
                  type="button"
                  className="play"
                  onClick={() => onPlayPauseClick(true)}
                  aria-label="Play"
                >
                  <Play />
                </button>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              {nextVisible ? (
                <button
                  type="button"
                  className="next"
                  aria-label="Next"
                  onClick={onNextClick}
                >
                  <Next />
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default AudioControls;
