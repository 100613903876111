import { useEffect, useState } from "react";
import AudioPlayer from "./AudioPlayer";

import tracksFromFile from "./Tracks";
import "./AudioPlayerContainer.css";

const AudioPlayerContainer = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [tracks, setTracks] = useState(null);

  useEffect(() => {
    setTracks(getShuffleTracks());
  }, []);

  const setPlayingStatus = (playing) => {
    setIsPlaying(playing);
  };

  const getShuffleTracks = () => {
    return tracksFromFile.sort(() => Math.random() - 0.5);
  };

  return (
    <div
      className="Audioplayer-App"
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <div
        className={`audio-player-container ${isPlaying ? "playing" : "idle"}`}
      >
        {tracks ? (
          <AudioPlayer tracks={tracks} setPlayingStatus={setPlayingStatus} />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
export default AudioPlayerContainer;
