import styled from "styled-components";

export default styled.button`
  z-index: 10;
  background: none;
  border-style: none;
  font-size: 50px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: white;
  :disabled {
    color: #ffffff21;
  }
`;
