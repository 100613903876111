import React, { useState, useEffect, useRef, useCallback } from "react";
import Artwork from "./Artwork";
import AudioControls from "./AudioControls";
import Backdrop from "./Backdrop";
import "./styles.css";

const AudioPlayer = ({ tracks, setPlayingStatus }) => {
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(new Audio(tracks[trackIndex].audioSrc));
  const intervalRef = useRef();
  const isReady = useRef(false);

  const { duration } = audioRef.current;

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;

  const toNextTrack = useCallback(() => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(tracks.length);
    }
  }, [trackIndex, tracks.length]);

  const startTimer = useCallback(() => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  }, [toNextTrack]);

  const onScrub = (value) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const toPrevTrack = () => {
    if (trackIndex - 1 < 0) {
      setTrackIndex(0);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  };

  useEffect(() => {
    setPlayingStatus(isPlaying);
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, setPlayingStatus, startTimer]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.pause();
    }
    audioRef.current = new Audio(tracks[trackIndex].audioSrc);

    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current && trackIndex > -1) {
      if (isPlaying) {
        audioRef.current.play();
        setIsPlaying(true);
        startTimer();
      }
    } else {
      isReady.current = true;
    }
  }, [trackIndex, isPlaying, startTimer, tracks]);

  return (
    <div className="audio-player">
      <div className="track-info">
        <Artwork isPlaying={isPlaying} />

        <h2 className="title">{tracks[trackIndex].title}</h2>
        <h3 className="artist">{tracks[trackIndex].artist}</h3>
        <AudioControls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
          prevVisible={trackIndex > 0}
          nextVisible={trackIndex < tracks.length - 1}
        />
        <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />
      </div>
      <Backdrop
        trackIndex={trackIndex}
        activeColor={tracks[trackIndex].color}
        isPlaying={isPlaying}
      />
    </div>
  );
};

export default AudioPlayer;
