import "./TopMenu.css";

const MenuItems = [
  "Start",
  "Termine",
  "Über Uns",
  "Audio",
  "Videos",
  "Bilder",
  "Kontakt",
];

const TopMenu = (props) => {
  return (
    <div className="menu-container">
      <ul className="menu-list-container">
        {MenuItems.map((element, index) => {
          return (
            <li
              key={index}
              className={
                "menu-item" +
                (props.selectedMenuItem === index + 1
                  ? " menu-item-selected"
                  : "")
              }
              onClick={() => props.clickHandler(index)}
            >
              {element}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default TopMenu;
