import { Button, Grid } from "@mui/material";

const Menueleiste = (props) => {
  return (
    <>
      <div style={{ width: "100%" }}>
        <Grid
          container
          width="100%"
          style={{
            marginTop: "15px",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          <Grid item xs={4}>
            <Button onClick={props.setParallaxInvisible} variant="contained">
              zurück
            </Button>
          </Grid>
          <Grid item container xs={8} justifyContent="flex-end">
            <Button variant="contained" style={{ marginRight: "20px" }}>
              {props.letzter ? "erster Typ" : "nächster Typ"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Menueleiste;
