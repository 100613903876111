import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "./Termine.css";

function createData(Datum, Uhrzeit, location, adresse, mapsLink) {
  return { Datum, Uhrzeit, location, adresse, mapsLink };
}

const rows = [
  createData(
    "30.03.2024",
    "19:00 Uhr",
    "7. Osterfeuer in Hönow",
    "Feuerwache Hönow",
    "https://maps.app.goo.gl/S6v8GoARfiPDAST7A"
  ),
  createData(
    "15.05.2024",
    "19:00 Uhr",
    "Open Mic",
    "Wabe Berlin",
    "https://maps.app.goo.gl/f8s5TyFXnP5ChDKw7"
  ),
  createData(
    "27.06.2024",
    "19:00 Uhr",
    "Vorband für Katha und die Dudes",
    "Junction Bar Berlin",
    "https://maps.app.goo.gl/AoXLZf2CCU8wN6z16"
  ),
];

const Termine = () => {
  return (
    <>
      <div
        className="Termine-App"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <div className="termine-center">
          <TableContainer
            sx={{
              backgroundColor: "transparent",
              color: "white",
            }}
            component={Paper}
          >
            <Table aria-label="termin tabelle">
              <TableHead>
                <TableRow>
                  <TableCell width="60" className="Zelle" align="center">
                    Datum
                  </TableCell>
                  <TableCell className="Zelle" align="center">
                    Veranstaltung
                  </TableCell>
                  <TableCell className="Zelle" align="right">
                    Adresse
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.Datum}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className="Zelle"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row.Datum}
                      <br />
                      {row.Uhrzeit}
                    </TableCell>
                    <TableCell className="Zelle" align="center">
                      {row.location}
                    </TableCell>
                    <TableCell className="Zelle" align="right">
                      <a href={row.mapsLink}> {row.adresse}</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};
export default Termine;
