const VideosContainer = () => {
  return (
    <div
      className="Video-App"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <iframe
        width="300"
        height="150"
        src="https://www.youtube.com/embed/tznbzqMHloI"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <iframe
        width="300"
        height="150"
        src="https://www.youtube.com/embed/bwu0QSqtbg8?si=qY4bZcQIig3ztPc9"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
};
export default VideosContainer;
