import { Grid } from "@mui/material";
import Menueleiste from "./Menueleiste";
import TextScherben from "./TextScherben";

const Bastel = (props) => {
  return (
    <>
      <div style={{ margin: "5%" }}>
        <Grid
          container
          rowSpacing={0}
          columnSpacing={2}
          style={{
            height: "100%",
            maxWidth: "900px",
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <img
              src="https://www.bunte-scherben.de/ressources/images/Steinchen.jpg"
              alt="Bastel"
              loading="lazy"
              width="100%"
              style={{ borderRadius: "10%" }}
            />
          </Grid>
          <Grid item xs={6} style={{ minWidth: "250px" }}>
            <div style={{ backgroundColor: "rgba(0,0,0,0.6)", padding: "5px" }}>
              <b>Bastel</b>, der Typ, der die Band im innersten zusammen hält.
              Erkennt jede Verstimmung und summt die schönsten Melodien für die
              Kollegen. Er hat den Takt im Blut und ist das Herz der&nbsp;
              <TextScherben />.
            </div>
          </Grid>
        </Grid>
        <Menueleiste setParallaxInvisible={props.setParallaxInvisible} />
      </div>
    </>
  );
};
export default Bastel;
