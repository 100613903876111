import intro from "../../ressources/AudioPlayer/intro.m4a";
import fuerImmerUndNackt from "../../ressources/AudioPlayer/nackt.m4a";
import leben from "../../ressources/AudioPlayer/nurLeben.m4a";
import heuteMorgen from "../../ressources/AudioPlayer/heuteMorgen.m4a";
import Wolken from "../../ressources/AudioPlayer/wolken.m4a";
import Neuro from "../../ressources/AudioPlayer/neuro.m4a";
import sauberesWasser from "../../ressources/AudioPlayer/sauberesWasser.m4a";
import prophet from "../../ressources/AudioPlayer/prophet.m4a";
import woGehenWirHin from "../../ressources/AudioPlayer/woGehenWirHin.m4a";

const TrackArray = [
  {
    title: "Intro",
    artist: "Bunte Scherben",
    audioSrc: intro,
    color: "#00aeb0",
  },
  {
    title: "für immer und Nackt",
    artist: "Bunte Scherben",
    audioSrc: fuerImmerUndNackt,
    color: "#81a172",
  },
  {
    title: "nur Leben",
    artist: "Bunte Scherben",
    audioSrc: leben,
    color: "#968547",
  },
  {
    title: "Wolken",
    artist: "Bunte Scherben",
    audioSrc: Wolken,
    color: "#c991bf",
  },
  {
    title: "Neurotransmittergewitter",
    artist: "Bunte Scherben",
    audioSrc: Neuro,
    color: "#344040",
  },
  {
    title: "sauberes Wasser",
    artist: "Bunte Scherben",
    audioSrc: sauberesWasser,
    color: "#082d85",
  },
  {
    title: "falscher Prophet",
    artist: "Bunte Scherben",
    audioSrc: prophet,
    color: "#e85cf7",
  },
  {
    title: "Heute Morgen",
    artist: "Bunte Scherben",
    audioSrc: heuteMorgen,
    color: "#21c56b",
  },
  {
    title: "Wo gehen wir hin",
    artist: "Bunte Scherben",
    audioSrc: woGehenWirHin,
    color: "#c8e3db",
  },
];

export default TrackArray;
