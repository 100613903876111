const TextScherben = () => {
  return (
    <>
      <b style={{ letterSpacing: "0.3em" }}>
        <span style={{ color: "red" }}>B</span>
        <span style={{ color: "Aquamarine" }}>U</span>
        <span style={{ color: "blue" }}>N</span>
        <span style={{ color: "yellow" }}>T</span>
        <span style={{ color: "green" }}>E</span>
        <span style={{ color: "green" }}>N</span>
        &nbsp;
        <span style={{ color: "brown" }}>S</span>
        <span style={{ color: "white" }}>C</span>
        <span style={{ color: "DarkOrange" }}>H</span>
        <span style={{ color: "GoldenRod" }}>E</span>
        <span style={{ color: "DeepPink" }}>R</span>
        <span style={{ color: "IndianRed" }}>B</span>
        <span style={{ color: "DarkKhaki" }}>E</span>
        <span style={{ color: "Indigo" }}>N</span>
      </b>
    </>
  );
};

export default TextScherben;
