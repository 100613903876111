import React from "react";
import styled from "styled-components";
import ButtonControl from "./NavigationButtonControl";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const NavigationButton = ({ position, onClick, disabled }) => {
  return (
    <Button
      position={position}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {position === "left" && <ArrowBackIosNewIcon />}
      {position === "right" && <ArrowForwardIosIcon />}
    </Button>
  );
};

const Button = styled(ButtonControl)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ position }) => (position === "left" ? "0px" : "unset")};
  right: ${({ position }) => (position === "right" ? "0px" : "unset")};
`;

export default NavigationButton;
