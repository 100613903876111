import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";

import "./NavigationHeader.css";

const NavigationHeader = ({ onClick, selectedItem, maxItems }) => {
  console.log("Auch ich wurde erstellt");
  return (
    <div className="TopHeaderBar">
      <div className="RightSideContainer">
        <Grid container sx={{ width: "100%" }} alignItems="center">
          <Grid item xs={6}>
            <div className="PageIndicator">
              {selectedItem} / {maxItems}
            </div>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <div style={{ paddingRight: "20px" }}>
              <CloseIcon
                onClick={onClick}
                style={{
                  borderStyle: "solid 3px",
                  borderRadius: "10px",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NavigationHeader;
