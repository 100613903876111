import { ImageList, ImageListItem } from "@mui/material";
import { useState } from "react";

import LightboxContainer from "./Lightbox/LightboxContainer";

const BildergalerieContainer = (props) => {
  const maxHeight = props.windowSize.innerHeight + "px";
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const handleImageClick = (index) => {
    console.log("Wurde ich geklickt?");
    setSelectedImageIndex(index);
    setLightboxOpen(true);
  };

  const handleLightboxClose = () => {
    setLightboxOpen(false);
  };

  return (
    <div
      className="Bildergalerie-App"
      style={{
        height: "100%",
        width: "100%",
        paddingTop: "30px",
      }}
    >
      <div
        style={{
          height: maxHeight,
          position: "relative",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        {lightboxOpen && (
          <LightboxContainer
            lightboxOpen={lightboxOpen}
            closeHandler={handleLightboxClose}
            selectedImage={selectedImageIndex}
            imageList={itemData}
          />
        )}

        <ImageList
          variant="masonry"
          cols={3}
          gap={8}
          sx={{
            height: "90%",
            width: "100%",
            marginTop: "10px",
            marginBottom: "30px",
          }}
        >
          {itemData.map((item, index) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
                onClick={() => handleImageClick(index)}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
};

const itemData = [
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen001.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/001.jpg",
    title: "POP UP CLUB",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen005.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/005.jpg",
    title: "Olli & Daniel",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen003.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/003.jpg",
    title: "Pedder & eN",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen004.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/004.jpg",
    title: "Soundcheck",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen002.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/002.jpg",
    title: "Schlepperei vor dem Auftritt",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen006.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/006.jpg",
    title: "Olli & Daniel",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen007.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/007.jpg",
    title: "Pedder",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen009.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/009.jpg",
    title: "Soundcheck",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen008.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/008.jpg",
    title: "Pop Gigant Olli",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen010.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/010.jpg",
    title: "Soundcheck",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen011.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/011.jpg",
    title: "das verdiente Ende",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen012.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/012.jpg",
    title: "Pedder & eN",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen013.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/013.jpg",
    title: "das verdiente Ende",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen014.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/014.jpg",
    title: "das verdiente Ende",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen015.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/015.jpg",
    title: "Olli",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen016.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/016.jpg",
    title: "Olli",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen017.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/017.jpg",
    title: "Prost",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen018.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/018.jpg",
    title: "Olli",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen019.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/019.jpg",
    title: "Prost Steinchen",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen020.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/020.jpg",
    title: "Pedder",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen021.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/021.jpg",
    title: "eN",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen022.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/022.jpg",
    title: "Olli",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen023.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/023.jpg",
    title: "Olli & Daniel",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen024.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/024.jpg",
    title: "Olli & Daniel",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen025.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/025.jpg",
    title: "Pedder",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen026.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/026.jpg",
    title: "eN",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen027.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/027.jpg",
    title: "Olli & Daniel",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen028.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/028.jpg",
    title: "Pedder & eN",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen029.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/029.jpg",
    title: "die zärtlichen Cousinen",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen030.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/030.jpg",
    title: "Olli & Daniel",
  },
  {
    img: "https://www.bunte-scherben.de/ressources/images/galerie/bildchen031.jpg",
    src: "https://www.bunte-scherben.de/ressources/images/galerie/big/031.jpg",
    title: "live",
  },
];

export default BildergalerieContainer;
