export const dieBilderListe = [
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_01.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_02.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_03.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_04.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_05.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_06.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_07.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_08.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_09.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_10.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_11.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_12.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_13.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_14.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_15.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_16.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_17.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_18.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_19.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_20.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_21.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_22.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_23.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_24.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_25.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_26.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_27.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_28.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_29.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_30.png",
  "https://bunte-scherben.de/ressources/images/Audioplayer/Artwork_31.png",
];
