import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

const FanDialog = (props) => {
  const [MailValue, setMailValue] = useState("");
  const [nachAbsenden, setNachAbsenden] = useState(false);

  const handleMailChange = (event) => {
    setMailValue(event.target.value);
  };

  let dialogText;
  let dialogOkayButtonText;
  if (nachAbsenden) {
    dialogText = "Super, das hat funktioniert!";
    dialogOkayButtonText = "Cool";
  } else {
    dialogText =
      "Wenn du über Änderungen der Webseite oder super Neuigkeiten in und um die Band benachrichtigt werden willst, gib deine Mail Adresse ein und wir informieren dich alsbald.";
    dialogOkayButtonText = "Ja, ich will";
  }

  const handleSubscribe = () => {
    if (nachAbsenden) {
      handleClose();
    } else {
      fetch("https://bunte-scherben.de/_PHPs/saveMail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: "text=" + MailValue,
      })
        .then((response) => response.text())
        .then((data) => console.log(data));

      setNachAbsenden(true);
    }
  };

  const handleClose = () => {
    setNachAbsenden(false);
    setMailValue("");
    props.closeHandler();
  };

  return (
    <div>
      <DialogTitle>{!nachAbsenden ? "Werde FAN" : ""}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
        {!nachAbsenden ? (
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Addresse"
            type="email"
            fullWidth
            variant="standard"
            value={MailValue}
            onChange={handleMailChange}
          />
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        {!nachAbsenden ? (
          <Button onClick={handleClose}>Abrechen</Button>
        ) : (
          <></>
        )}
        <Button onClick={handleSubscribe}>{dialogOkayButtonText}</Button>
      </DialogActions>
    </div>
  );
};
export default FanDialog;
